import { defineStore } from "pinia";

interface State {
  accessToken: string;
  refreshToken: string;
  isAuthenticated: boolean;
}

export const useAuthStore = defineStore({
  id: "auth",
  state: (): State => ({
    accessToken: "",
    refreshToken: "",
    isAuthenticated: false,
  }),
  actions: {
    login(accessToken: string, refreshToken: string) {
      this.accessToken = accessToken;
      this.refreshToken = refreshToken;
      this.isAuthenticated = true;
    },
    logout() {
      this.accessToken = "";
      this.refreshToken = "";
      this.isAuthenticated = false;
    },
  },
  persist: {
    enabled: true,
  },
});
