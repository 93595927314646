import Vue, { PluginFunction, PluginObject } from "vue";
import * as R from "ramda";

declare module "vue/types/vue" {
  interface Vue {
    $R: typeof R;
  }
}

class VueRamda implements PluginObject<Vue> {
  install: PluginFunction<Vue> = (Vue) => {
    Vue.prototype.$R = R;
  };
}

const vueRamda = new VueRamda();

Vue.use(vueRamda);
