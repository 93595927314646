import { defineStore } from "pinia";

interface State {
  active: boolean;
  color: string;
  timeout: number;
  text: string;
}

export const useSnackBarStore = defineStore({
  id: "snackBar",
  state: (): State => ({
    active: false,
    color: "",
    timeout: -1,
    text: "",
  }),
  actions: {
    showAlert(text: string, timeout = 3000) {
      this.active = true;
      this.text = text;
      this.timeout = timeout;
      this.color = "error";
    },
    showSuccess(text: string, timeout = 3000) {
      this.active = true;
      this.text = text;
      this.timeout = timeout;
      this.color = "success";
    },
  },
});
