import VueAxios from "vue-axios";
import axios from "axios";
import Vue from "vue";
import { useAuthStore } from "@/stores/auth";

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.interceptors.request.use((config) => {
  if (config.headers) {
    const store = useAuthStore();
    config.headers["Authorization"] = store.accessToken;
  }
  return config;
});
Vue.use(VueAxios, axios);
