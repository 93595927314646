import { defineStore } from "pinia";

type LanguageType = "en_us" | "zh_cn" | "ja_jp";

type DarkModeType = "system" | "light" | "dark";

interface State {
  language: LanguageType;
  darkMode: DarkModeType;
}

export const useAppStore = defineStore({
  id: "app",
  state: (): State => ({
    language: "zh_cn",
    darkMode: "light",
  }),
  persist: {
    enabled: true,
    strategies: [
      {
        storage: localStorage,
      },
    ],
  },
});
