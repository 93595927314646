export default {
  app: {
    name: "AIマッチング",
    description: "案件に最適な人材をマッチング",
    leftSideBar: {
      dashboard: "ダッシュボード",
      candidate: "人材リスト",
      recruitment: "案件リスト",
      search: "検索",
      history: "履歴",
      settings: "設定",
      profile: "アカウント情報",
    },
    constants: {
      gender: {
        male: "男",
        female: "女",
        unknown: "その他",
      },
      education: {
        degree: {
          BelowAssociate: "高卒",
          Associate: "専門学校",
          Bachelor: "大学学士",
          Master: "大学修士",
          Doctorate: "大学博士",
          Other: "その他",
        },
        major: {
          csRelated: "IT関連専攻",
          nonCsRelated: "IT非関連専攻",
          other: "その他",
        },
      },
      process: {
        REQUIREMENT: "要件定義",
        BASIC_DESIGN: "基本設計",
        DETAIL_DESIGN: "詳細設計",
        IMPLEMENTATION: "開発",
        UNIT_TEST: "単体テスト",
        INTEGRATION_TEST: "結合テスト",
        MAINTENANCE: "保守",
      },
      recruitmentStatus: {
        1: "応募中",
        2: "終了",
        99: "不明",
      },
      recruitmentRemoteType: {
        1: "通勤",
        2: "一部リモート",
        3: "フルリモート",
        99: "不明",
      },
    },
  },
  login: {
    title: "AI人材マッチングシステムへようこそ",
    username: "メールアドレス",
    password: "パスワード",
    login: "ログイン",
    register: "サインアップ",
    forgotPassword: "パスワードを忘れた？",
  },
  dashboard: {
    candidates: {
      title: "登録人材数",
    },
    recruitments: {
      title: "マッチング成功数",
    },
  },
  candidateView: {
    tableTop: {
      title: "人材リスト",
      add: "新規",
      ocr: "OCR",
    },
    tableHeader: {
      id: "ID",
      name: "氏名",
      gender: "性别",
      age: "年齢",
      nationality: "国籍",
      actions: "操作",
    },
  },
  candidateCreationView: {
    tabs: {
      basicInfo: "基本情報",
      educations: "学歴",
      skills: "スキル",
      experience: "職歴",
    },
    buttons: {
      save: "保存",
      reset: "リセット",
      edit: "編集",
    },
    addEducation: "学歴追加",
    editEducation: "学歴編集",
    addSkill: "スキル追加",
    editSkill: "スキル編集",
    addExperience: "職歴追加",
    editExperience: "職歴編集",
    messages: {
      updateSuccess: "更新成功",
    },
  },
  candidateOCRView: {
    title: "OCR",
    inputs: {
      file: "アップロード",
      submit: "提出",
    },
    dialogs: {
      standBy: "少々お待ちください...",
      noFile: "ファイルをアップロードしてください",
    },
  },
  candidateBasicInfoForm: {
    title: "人材情報",
    fields: {
      familyName: "姓（漢字）",
      firstName: "名（漢字）",
      familyNameKana: "姓（カナ）",
      firstNameKana: "名（カナ）",
      familyNameEn: "姓（ローマ字）",
      firstNameEn: "名（ローマ字）",
      gender: "性别",
      birthPlace: "国籍",
      birthDate: "誕生日",
      age: "年齢",
      birthplace: "国籍",
      email: "メールアドレス",
      phone: "電話番号",
      zip: "郵便番号",
      prefecture: "都道府県",
      city: "市",
      address: "区町村",
      building: "建物名",
      nearestStation: "最寄り駅",
      nearestStationSearch: "駅検索",
      company: "現属企業",
      desiredPrice: "希望収入",
      selfIntroduction: "自己pr",
      comment: "備考",
    },
    genderOptions: {
      male: "男",
      female: "女",
      other: "その他",
    },
    rules: {
      familyNameRequired: "姓は必須",
      firstNameRequired: "名は必須",
    },
  },
  candidateEducationsForm: {
    buttons: {
      save: "保存",
      cancel: "取消",
    },
    fields: {
      school: "学校",
      degree: "学位",
      major: "分野",
      start: "開始日",
      end: "終了日",
      actions: "操作",
    },
  },
  candidateSkillsForm: {
    save: "保存",
    cancel: "取消",
    confirm: "確認",
    fields: {
      skill: "スキル",
      level: "レベル",
      actions: "操作",
    },
  },
  candidateExperienceForm: {
    save: "保存",
    cancel: "取消",
    fields: {
      name: "案件タイトル",
      description: "案件内容",
      startDate: "開始日",
      endDate: "終了日",
      isCurrent: "今働いている",
      position: "役職",
      role: "役割",
      teamSize: "PJ規模",
      skills: "スキル",
      processes: "参加工程",
      actions: "操作",
    },
  },
  recruitmentListView: {
    table: {
      top: {
        title: "案件リスト",
        add: "新規",
      },
      header: {
        name: "案件タイトル",
        price: "単価",
        location: "勤務地",
        startDate: "開始日",
        remote: "出勤/リモート",
        status: "ステータス",
        actions: "操作",
      },
    },
  },
  recruitmentCreationView: {
    buttons: {
      save: "保存",
      reset: "リセット",
    },
  },
  recruitmentDetailView: {
    basic: {
      title: "基本情報",
      fields: {
        name: "PJタイトル",
        description: "PJ内容",
        price: "単価",
        location: "勤務地",
        remote: "出勤/リモート",
        startDate: "開始日",
        time: "就業時間",
        status: "ステータス",
        skills: "スキル",
      },
      actions: {
        ok: "确定",
        cancel: "取消",
      },
    },
    searchHistory: {
      title: "検索履歴",
      fields: {
        date: "時間",
        count: "人数",
      },
    },
    actions: {
      save: "保存",
      reset: "リセット",
      search: "この情報でマッチング",
    },
  },
  searchHistoryDetailView: {
    table: {
      header: {
        name: "氏名",
        age: "年齢",
        suitability: "マッチング度合",
        actions: "操作",
      },
    },
  },
  searchView: {
    input: {
      label: "検索",
    },
    table: {
      header: {
        name: "氏名",
        suitability: "マッチング度合",
      },
    },
  },
  settingsView: {
    languageOptions: {
      zh_cn: "中文",
      en_us: "English",
      ja_jp: "日本語",
    },
    darkModeOptions: {
      light: "ライトモード",
      dark: "ダークモード",
      system: "システムと同様",
    },
    form: {
      title: "设置",
      language: "言語",
      darkMode: "ダークモード",
      save: "保存",
    },
  },
  profileView: {
    name: "氏名",
    email: "メールアドレス",
    status: "ステータス",
    role: "役割",
    phone: "電話番号",
    buttons: {
      editProfile: "プロフィール編集",
      changePassword: "パスワード変更",
      logout: "ログアウト",
    },
  },
};
