export default {
  app: {
    name: "智能匹配",
    description: "根据需要匹配最佳的人才",
    leftSideBar: {
      dashboard: "仪表盘",
      candidate: "人才",
      recruitment: "招聘",
      search: "搜索",
      history: "历史",
      settings: "设置",
      profile: "个人资料",
    },
    constants: {
      gender: {
        male: "男",
        female: "女",
        unknown: "保密",
      },
      education: {
        degree: {
          BelowAssociate: "专科以下",
          Associate: "专科",
          Bachelor: "本科",
          Master: "硕士",
          Doctorate: "博士",
          Other: "其他",
        },
        major: {
          csRelated: "计算机相关",
          nonCsRelated: "非计算机相关",
          other: "其他",
        },
      },
      process: {
        REQUIREMENT: "需求",
        BASIC_DESIGN: "基础设计",
        DETAIL_DESIGN: "详细设计",
        IMPLEMENTATION: "实现",
        UNIT_TEST: "单元测试",
        INTEGRATION_TEST: "集成测试",
        MAINTENANCE: "维护",
      },
      recruitmentStatus: {
        1: "招募中",
        2: "已关闭",
        99: "未知",
      },
      recruitmentRemoteType: {
        1: "办公室",
        2: "部分远程",
        3: "完全远程",
        99: "未知",
      },
    },
  },
  login: {
    title: "欢迎使用智能匹配",
    username: "邮箱",
    password: "密码",
    login: "登录",
    register: "注册",
    forgotPassword: "忘记密码",
  },
  dashboard: {
    candidates: {
      title: "人才总数",
    },
    recruitments: {
      title: "招聘总数",
    },
  },
  candidateView: {
    tableTop: {
      title: "人才列表",
      add: "新建",
      ocr: "OCR",
    },
    tableHeader: {
      id: "ID",
      name: "姓名",
      gender: "性别",
      age: "年龄",
      nationality: "国籍",
      actions: "操作",
    },
  },
  candidateCreationView: {
    tabs: {
      basicInfo: "基本信息",
      educations: "教育经历",
      skills: "技能",
      experience: "工作经历",
    },
    buttons: {
      save: "保存",
      reset: "重置",
      edit: "编辑",
    },
    addEducation: "添加教育经历",
    editEducation: "编辑教育经历",
    addSkill: "添加技能",
    editSkill: "编辑技能",
    addExperience: "添加工作经历",
    editExperience: "编辑工作经历",
    messages: {
      updateSuccess: "更新成功",
    },
  },
  candidateOCRView: {
    title: "OCR",
    inputs: {
      file: "上传文件",
      submit: "提交",
    },
    dialogs: {
      standBy: "处理中，请稍后...",
      noFile: "请上传文件",
    },
  },
  candidateBasicInfoForm: {
    title: "人才信息",
    fields: {
      familyName: "姓（汉字）",
      firstName: "名（汉字）",
      familyNameKana: "姓（カナ）",
      firstNameKana: "名（カナ）",
      familyNameEn: "姓（罗马字）",
      firstNameEn: "名（罗马字）",
      gender: "性别",
      birthPlace: "国籍",
      birthDate: "出生日期",
      age: "年龄",
      birthplace: "国籍",
      email: "邮箱",
      phone: "电话",
      zip: "邮编",
      prefecture: "都道府县",
      city: "城市",
      address: "区町村",
      building: "建筑名",
      nearestStation: "最近的地铁站",
      nearestStationSearch: "搜索地铁站",
      company: "当前公司",
      desiredPrice: "期望薪资",
      selfIntroduction: "自我介绍",
      comment: "备注",
    },
    genderOptions: {
      male: "男",
      female: "女",
      other: "其他",
    },
    rules: {
      familyNameRequired: "姓是必填项",
      firstNameRequired: "名是必填项",
    },
  },
  candidateEducationsForm: {
    buttons: {
      save: "保存",
      cancel: "取消",
    },
    fields: {
      school: "学校",
      degree: "学位",
      major: "专业",
      start: "开始日期",
      end: "结束日期",
      actions: "操作",
    },
  },
  candidateSkillsForm: {
    save: "保存",
    cancel: "取消",
    confirm: "确认",
    fields: {
      skill: "技能",
      level: "熟练度",
      actions: "操作",
    },
  },
  candidateExperienceForm: {
    save: "保存",
    cancel: "取消",
    fields: {
      name: "项目名称",
      description: "项目描述",
      startDate: "开始日期",
      endDate: "结束日期",
      isCurrent: "是否在职",
      position: "职位",
      role: "角色",
      teamSize: "团队规模",
      skills: "技能",
      processes: "参与流程",
      actions: "操作",
    },
  },
  recruitmentListView: {
    table: {
      top: {
        title: "招聘列表",
        add: "新建",
      },
      header: {
        name: "工作名称",
        price: "单价",
        location: "工作地点",
        startDate: "开始日期",
        remote: "远程形态",
        status: "状态",
        actions: "操作",
      },
    },
  },
  recruitmentCreationView: {
    buttons: {
      save: "保存",
      reset: "重置",
    },
  },
  recruitmentDetailView: {
    basic: {
      title: "基本信息",
      fields: {
        name: "项目名称",
        description: "项目描述",
        price: "单价",
        location: "工作地点",
        remote: "远程形态",
        startDate: "开始日期",
        time: "工作时间",
        status: "状态",
        skills: "技能",
      },
      actions: {
        ok: "确定",
        cancel: "取消",
      },
    },
    searchHistory: {
      title: "搜索历史",
      fields: {
        date: "时间",
        count: "人数",
      },
    },
    actions: {
      save: "保存",
      reset: "重置",
      search: "搜索",
    },
  },
  searchHistoryDetailView: {
    table: {
      header: {
        name: "姓名",
        age: "年龄",
        suitability: "匹配度",
        actions: "操作",
      },
    },
  },
  searchView: {
    input: {
      label: "搜索",
    },
    table: {
      header: {
        name: "姓名",
        suitability: "匹配度",
      },
    },
  },
  settingsView: {
    languageOptions: {
      zh_cn: "中文",
      en_us: "English",
      ja_jp: "日本語",
    },
    darkModeOptions: {
      light: "浅色",
      dark: "深色",
      system: "跟随系统",
    },
    form: {
      title: "设置",
      language: "语言",
      darkMode: "黑暗模式",
      save: "保存",
    },
  },
  profileView: {
    name: "姓名",
    email: "邮箱",
    status: "状态",
    role: "角色",
    phone: "电话",
    buttons: {
      editProfile: "编辑个人资料",
      changePassword: "修改密码",
      logout: "登出",
    },
  },
};
