import Vue, { PluginFunction, PluginObject } from "vue";
import moment from "moment";

declare module "vue/types/vue" {
  interface Vue {
    $moment: typeof moment;
  }
}

class VueMoment implements PluginObject<Vue> {
  install: PluginFunction<Vue> = (Vue) => {
    Vue.prototype.$moment = moment;
  };
}

const vueMoment = new VueMoment();

Vue.use(vueMoment);
