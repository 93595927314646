import Vue from "vue";
import App from "@/App.vue";
import pinia from "@/stores";
import router from "@/router";
import i18n from "@/i18n";
import vuetify from "@/plugins/vuetify";
import "@/plugins/ramda";
import "@/plugins/axios";
import "@/plugins/moment";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";

Vue.config.productionTip = false;

new Vue({
  pinia,
  router,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
