import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import { useAuthStore } from "@/stores/auth";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "dashboard",
    components: {
      default: () => import("@/views/DashboardView.vue"),
      AppHeader: () => import("@/components/AppHeader.vue"),
      LeftSidebar: () => import("@/components/LeftSidebar.vue"),
    },
  },
  {
    path: "/login",
    name: "login",
    components: {
      default: () => import("@/views/LoginView.vue"),
    },
  },
  {
    path: "/candidate",
    name: "candidate",
    components: {
      default: () => import("@/views/CandidateView.vue"),
      AppHeader: () => import("@/components/AppHeader.vue"),
      LeftSidebar: () => import("@/components/LeftSidebar.vue"),
    },
  },
  {
    path: "/candidate/create",
    name: "candidateCreation",
    components: {
      default: () => import("@/views/CandidateCreationView.vue"),
      AppHeader: () => import("@/components/AppHeader.vue"),
      LeftSidebar: () => import("@/components/LeftSidebar.vue"),
    },
  },
  {
    path: "/candidate/ocr",
    name: "candidateOCR",
    components: {
      default: () => import("@/views/CandidateOCRView.vue"),
      AppHeader: () => import("@/components/AppHeader.vue"),
      LeftSidebar: () => import("@/components/LeftSidebar.vue"),
    },
  },
  {
    path: "/candidate/ocr/edit",
    name: "candidateOCREdit",
    components: {
      default: () => import("@/views/CandidateOCREditView.vue"),
      AppHeader: () => import("@/components/AppHeader.vue"),
      LeftSidebar: () => import("@/components/LeftSidebar.vue"),
    },
  },
  {
    path: "/candidate/:id",
    name: "candidateDetail",
    components: {
      default: () => import("@/views/CandidateDetailView.vue"),
      AppHeader: () => import("@/components/AppHeader.vue"),
      LeftSidebar: () => import("@/components/LeftSidebar.vue"),
    },
  },
  {
    path: "/candidate/:id/edit",
    name: "candidateEdit",
    components: {
      default: () => import("@/views/CandidateEditView.vue"),
      AppHeader: () => import("@/components/AppHeader.vue"),
      LeftSidebar: () => import("@/components/LeftSidebar.vue"),
    },
  },
  {
    path: "/recruitment",
    name: "recruitment",
    components: {
      default: () => import("@/views/RecruitmentListView.vue"),
      AppHeader: () => import("@/components/AppHeader.vue"),
      LeftSidebar: () => import("@/components/LeftSidebar.vue"),
    },
  },
  {
    path: "/recruitment/create",
    name: "recruitmentCreation",
    components: {
      default: () => import("@/views/RecruitmentCreationView.vue"),
      AppHeader: () => import("@/components/AppHeader.vue"),
      LeftSidebar: () => import("@/components/LeftSidebar.vue"),
    },
  },
  {
    path: "/recruitment/:id",
    name: "recruitmentDetail",
    components: {
      default: () => import("@/views/RecruitmentDetailView.vue"),
      AppHeader: () => import("@/components/AppHeader.vue"),
      LeftSidebar: () => import("@/components/LeftSidebar.vue"),
    },
  },
  {
    path: "/search-history/:projectID/:id",
    name: "searchHistoryDetail",
    components: {
      default: () => import("@/views/SearchHistoryDetailView.vue"),
      AppHeader: () => import("@/components/AppHeader.vue"),
      LeftSidebar: () => import("@/components/LeftSidebar.vue"),
    },
  },
  {
    path: "/settings",
    name: "settings",
    components: {
      default: () => import("@/views/SettingsView.vue"),
      AppHeader: () => import("@/components/AppHeader.vue"),
      LeftSidebar: () => import("@/components/LeftSidebar.vue"),
    },
  },
  {
    path: "/profile",
    name: "profile",
    components: {
      default: () => import("@/views/ProfileView.vue"),
      AppHeader: () => import("@/components/AppHeader.vue"),
      LeftSidebar: () => import("@/components/LeftSidebar.vue"),
    },
  },
  {
    path: "*",
    name: "not-found",
    component: () => import("@/views/NotFoundView.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const isAuthenticated = (): boolean => {
  const authStore = useAuthStore();
  return authStore.isAuthenticated;
};

router.beforeEach((to, from, next) => {
  if (to.name !== "login" && !isAuthenticated()) {
    next({ name: "login" });
  } else {
    next();
  }
});

export default router;
