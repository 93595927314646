
import { defineComponent } from "vue";
import { useAppStore } from "@/stores/app";
import { useSnackBarStore } from "@/stores/snack-bar";
import { mapState, mapWritableState } from "pinia";

export default defineComponent({
  name: "App",
  setup() {
    const appStore = useAppStore();
    return {
      appStore,
    };
  },
  beforeMount() {
    this.appStore.$subscribe(() => {
      this.updateDarkMode();
      this.updateLanguage();
    });
  },
  computed: {
    ...mapWritableState(useSnackBarStore, ["active"]),
    ...mapState(useSnackBarStore, ["text", "color", "timeout"]),
  },
  methods: {
    updateDarkMode() {
      switch (this.appStore.darkMode) {
        case "light":
          this.$vuetify.theme.dark = false;
          break;
        case "dark":
          this.$vuetify.theme.dark = true;
          break;
        case "system":
          this.$vuetify.theme.dark = window.matchMedia(
            "(prefers-color-scheme: dark)"
          ).matches;
          break;
      }
    },
    updateLanguage() {
      this.$i18n.locale = this.appStore.language;
      switch (this.appStore.language) {
        case "zh_cn":
          this.$vuetify.lang.current = "zhHans";
          this.$moment.locale("zh-cn");
          break;
        case "ja_jp":
          this.$vuetify.lang.current = "ja";
          this.$moment.locale("ja");
          break;
        case "en_us":
          this.$vuetify.lang.current = "en";
          this.$moment.locale("en");
          break;
      }
    },
  },
});
